import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart3 = () => {
  const series = [45, 65];

  return (
    <ReactApexChart
      options={{
        chart: {
          toolbar: {
            show: false,
          },
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      }}
      series={series}
      type="donut"
      width="100%"
    ></ReactApexChart>
  );
};

export default PieChart3;

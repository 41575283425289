import "../../components/style/navbar.css";
import "./styles.css";

const MainLayout = ({
  children,
  headerName,
  rightComponent,
  subTitle1,
  subTitle2,
}: any) => {
  return (
    <div className="main-container">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            minHeight: "50px",
            marginTop: "10px",
          }}
        >
          <div className="header-name">
            {subTitle1 && <p className="sub-title-1">{subTitle1}</p>}
            {headerName}
            {subTitle2 && <p className="sub-title-2">{subTitle2}</p>}
          </div>
          {rightComponent}
        </div>
        <hr></hr>
        <div className="child-container">{children}</div>
      </div>
      <div style={{ height: "20px", marginTop: "50px" }}>
        {/* <hr></hr>
        Copy right 2022 Golf cloud */}
      </div>
    </div>
  );
};
export default MainLayout;

import React from "react";
import { Button, Col, Divider, Row, Upload } from "antd";
import "./style.css";
import SelectDateTime from "../screens/admin/sessions/inputFeilds/selectDate";
import { MainLayout } from "../layouts";
import { useNavigate } from "react-router-dom";

const UploadSession: React.FC = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/admin/session/recent`;
    navigate(path);
  };
  return (
    <MainLayout headerName={"Sessions"}>
      <div>
        <div
          style={{
            marginTop: 50,
          }}
        >
          <div
            className="uploading-box-shadow"
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                fontFamily: "Plus Jakarta Sans",
                fontWeight: 700,
                fontSize: 24,
                textAlign: "center",
              }}
            >
              Upload Session Data
            </h1>

            <div></div>
            <SelectDateTime />
            <div
              style={{
                marginTop: 10,
                borderColor: "#047EB1",
                color: "#FFF",
                fontWeight: "bold",
                fontFamily: "Plus Jakarta Sans",
                fontSize: 16,
                padding: 12,
                textAlign: "center",
                cursor: "pointer",
              }}
              className="add-upload-file"
              onClick={() => {
                routeChange();
              }}
            >
              SAVE & ADD SESSION DATE TO PROFILE
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default UploadSession;

import React, { lazy, Suspense } from "react";

import { Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";
import CustomRouter from "./CustomRouter";
import Success from "../components/SuccessInfo/Success";
import Graphs from "../components/screens/home/Graphs";
import EventsTable from "../components/screens/admin/tables/EventsTable";
import SimpleTables from "../components/screens/admin/tables/SampleTable";
import SessionTime from "../components/screens/admin/sessions/session";
import Navbar from "../components/screens/admin/sessions/Navbar";
import UploadSession from "../components/Upload/uploadsession";
import Sessions from "../components/screens/admin/sessions/Sessions2";
import SessionTable from "../components/screens/admin/sessions/tables/SessionTable";
import SessionList from "../components/screens/admin/sessions/tables/SessionList";
import SessionsGraph from "../components/screens/admin/sessions/graphs/SessionsGraph";

export const history = createBrowserHistory();

const Home = lazy(() => import("../components/screens/home"));
const AdminLogin = lazy(
  () => import("../components/screens/admin/auth/Signin")
);
const AdminForgot = lazy(
  () => import("../components/screens/admin/auth/ForgotPassword")
);
const AdminResetSuccess = lazy(
  () => import("../components/screens/admin/auth/PasswordResetSuccess")
);
const CreateEvent = lazy(
  () => import("../components/screens/admin/events/CreateEvent")
);
const UsersTable = lazy(
  () => import("../components/screens/admin/tables/UsersTable")
);
// const SuccessInfo = lazy(() => import("components/SuccessInfo/Success"));
const routesView = () => {
  const showNavBar = () => {
    if (
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/forgot" &&
      window.location.pathname !== "/success" &&
      window.location.pathname !== "/"
    ) {
      return true;
    }
    return false;
  };

  return (
    <Suspense fallback={null}>
      <CustomRouter history={history}>
        <div
          style={{
            display: "flex",
            maxHeight: "100vh",
            overflow: "hidden",
            width: "100vw",
          }}
        >
          {showNavBar() && <Navbar />}
          <Routes>
            <Route path="/" element={<AdminLogin />} />
            <Route path="/login" element={<AdminLogin />} />
            <Route path="/forgot" element={<AdminForgot />} />
            <Route path="/success" element={<AdminResetSuccess />} />
            <Route path="/admin/table/users" element={<UsersTable />} />
            {/* <Route path="/admin/success" element={<SuccessInfo />} /> */}
            {/* These things below are MEANT to be protected/private routes */}
            <Route path="/admin/dashboard" element={<Home />} />
            <Route path="/admin/create/event" element={<CreateEvent />} />
            <Route path="/admin/graphs" element={<Graphs />} />
            <Route path="/admin/events" element={<EventsTable />} />
            <Route path="/admin/menutable" element={<SimpleTables />} />
            <Route path="/admin/session" element={<SessionTime />} />
            <Route path="/admin/session/upload" element={<UploadSession />} />
            <Route path="/admin/session/recent" element={<Sessions />} />
            <Route path="/admin/session/table" element={<SessionTable />} />
            <Route path="/admin/session/list" element={<SessionList />} />
            <Route path="/admin/session/graph" element={<SessionsGraph />} />
          </Routes>
        </div>
      </CustomRouter>
    </Suspense>
  );
};

export default routesView;

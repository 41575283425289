import React from "react";
import { ResponsiveContainer, PieChart, Pie, Legend, Cell } from "recharts";

const data = [
  { name: "Total \n 29,246,83", value: 400 },
  { name: "Total \n 29,246,83", value: 300 },
];

const COLORS = ["#198658", "#F28F1B"];

export default function App() {
  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <PieChart
          width={730}
          height={250}
          margin={{ top: -70, right: 0, left: -16, bottom: 0 }}
        >
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#82ca9d"
            label
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

// export default function App() {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const onPieEnter = useCallback(
//     (_: any, index: any) => {
//       setActiveIndex(index);
//     },
//     [setActiveIndex]
//   );

//   return (
//     <ResponsiveContainer width={700} height="100%">
//       <PieChart margin={{ top: , right: 0, left: -16, bottom: 0 }}>
//         <Pie
//           activeIndex={activeIndex}
//           activeShape={renderActiveShape}
//           data={data}
//           cx={200}
//           cy={200}
//           innerRadius={60}
//           outerRadius={80}
//           dataKey="value"
//           onMouseLeave={onPieEnter}
//           tooltipType={"none"}
//         >
//           {data.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//           ))}
//         </Pie>
//       </PieChart>
//     </ResponsiveContainer>
//   );
// }

// import React from "react";
// import ReactApexChart from "react-apexcharts";

// const areaApex = () => {
//   const series = [45, 65];

//   return (
//     <ReactApexChart
//       options={{
//         chart: {
//           toolbar: {
//             show: false,
//           },
//           type: "donut",
//         },
//         responsive: [
//           {
//             breakpoint: 480,
//             options: {
//               chart: {
//                 width: 200,
//               },
//               legend: {
//                 position: "bottom",
//               },
//             },
//           },
//         ],
//       }}
//       series={series}
//       type="donut"
//       width="100%"
//     ></ReactApexChart>
//   );
// };

// export default areaApex;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import GolfCloud from "../../../../assets/images/GC-Logo.svg";
import SessionIcon from "../../../../assets/images/Sessions.svg";
import SessionBlueIcon from "../../../../assets/images/SessionIconBlue.svg";
import AcademyIcon from "../../../../assets/images/Academy.svg";
import AcademyBlueIcon from "../../../../assets/images/AcademyBlue.svg";
import DashboardIcon from "../../../../assets/images/Dashboard.svg";
import DashboardIconBlue from "../../../../assets/images/DashboardBlue.svg";
import CourseIcon from "../../../../assets/images/Courses.svg";
import CourseIconBlue from "../../../../assets/images/CoursesIconBlue.svg";
import katie from "../../../../assets/images/katie.png";
import "../../../style/navbar.css";
import { UserOutlined, RightOutlined } from "@ant-design/icons";
import ArrowIcon from "../../../../assets/images/Arrow.svg";
import { Avatar, Badge } from "antd";
import "../../../style/navbar.css";
import "../../../style/index.css";

const ICON_SIZE = 20;

function Navbar({ visible, show }: any) {
  const [dashboard, setDashboard] = useState(false);
  const [session, setSession] = useState(false);
  const [academy, setAcademy] = useState(false);
  const [course, setCourse] = useState(false);
  const [selected, setSelected] = useState<any>(null);

  return (
    <div className="nav">
      <div>
        <div className="logo">
          <img
            src={GolfCloud}
            style={{ width: "150px", height: "60px" }}
            alt="logo"
          />
        </div>
        <div className="links nav-top">
          <NavLink
            to="/dashboard"
            onMouseEnter={() => {
              setDashboard(true);
            }}
            onMouseLeave={() => {
              setDashboard(false);
            }}
            onClick={() => {
              setDashboard(true);
              setSelected(0);
            }}
            className={
              selected === 0 ? "nav-link nav-link-selected" : "nav-link"
            }
          >
            <img
              src={
                dashboard || selected === 0 ? DashboardIconBlue : DashboardIcon
              }
              alt="name"
            />
            Dashboard
          </NavLink>
          <NavLink
            to="/admin/session/recent"
            className={
              selected === 1 ? "nav-link nav-link-selected" : "nav-link"
            }
            onMouseEnter={() => {
              setSession(true);
            }}
            onMouseLeave={() => {
              setSession(false);
            }}
            onClick={() => {
              setSession(true);
              setSelected(1);
            }}
          >
            <img
              src={session || selected === 1 ? SessionBlueIcon : SessionIcon}
              alt="name"
            />
            Sessions
          </NavLink>
          <NavLink
            to="/orders"
            className={
              selected === 2 ? "nav-link nav-link-selected" : "nav-link"
            }
            onClick={() => {
              setAcademy(true);
              setSelected(2);
            }}
            onMouseEnter={() => {
              setAcademy(true);
            }}
            onMouseLeave={() => {
              setAcademy(false);
            }}
          >
            <img
              src={academy || selected === 2 ? AcademyBlueIcon : AcademyIcon}
              alt="name"
            />
            Academy
          </NavLink>
          <NavLink
            to="/course"
            className={
              selected === 3 ? "nav-link nav-link-selected" : "nav-link"
            }
            onClick={() => {
              setSelected(3);
            }}
            onMouseEnter={() => {
              setCourse(true);
            }}
            onMouseLeave={() => {
              setCourse(false);
            }}
          >
            <img
              src={course || selected === 3 ? CourseIconBlue : CourseIcon}
              alt="name"
            />
            Courses
          </NavLink>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "70px",
          paddingLeft: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            cursor: "pointer",
          }}
        >
          <div className="badge">4</div>
          <img className="profile-pic" src={katie} alt="name" />
        </div>
        <div style={{ marginLeft: "10px", cursor: "pointer" }}>
          <h6 className="profile-name">Katie {">"}</h6>
          <p className="profile-email">katie@email.com</p>
        </div>
      </div>
    </div>
  );
}

export default Navbar;

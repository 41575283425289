import type { TableProps } from "antd";
import { Table, Row, Col } from "antd";
import type {
  ColumnsType,
  SorterResult,
  TableRowSelection,
  FilterValue,
} from "antd/lib/table/interface";
import "antd/dist/antd.css";
import { ReactComponent as ForeSight } from "../../../../assets/icons/foresight3.svg";
import React, { useState } from "react";
import "./styles.css";
import { sampleData } from "../tables/constantData";

interface DataType {
  id: string;
  name: string;
  club_speed: number;
  world_points: number;
  event_type: string;
  ball_speed: string;
  rules: string;
  start_time: string;
  end_time: string;
  spin_rate: string;
  carry_distance: string;
  launch_angle: string;
  registration_deadline: string;
  created_at: string;
}

const EventsTable: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const handleChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<DataType>);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record, // Column configuration not to be checked
      name: record,
    }),
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const columns: ColumnsType<DataType> = [
    {
      title: <p className="table-column-text">CLUB</p>,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
      width: 50,
    },
    {
      title: <p className="table-column-text">CLUB SPEED</p>,
      dataIndex: "club_speed",
      key: "club_speed",
      sorter: (a, b) => a.club_speed - b.club_speed,
      sortOrder:
        sortedInfo.columnKey === "club_speed" ? sortedInfo.order : null,
      ellipsis: true,
      width: 70,
    },
    {
      title: <p className="table-column-text">BALL SPEED</p>,
      dataIndex: "ball_speed",
      key: "ball_speed",
      sorter: (a) => a.ball_speed.length,
      sortOrder:
        sortedInfo.columnKey === "ball_speed" ? sortedInfo.order : null,
      ellipsis: true,
      width: 70,
    },
    {
      title: <p className="table-column-text">LAUNCH ANGLE</p>,
      dataIndex: "launch_angle",
      key: "launch_angle",
      sorter: (a: any, b: any) => a.launch_angle - b.launch_angle,
      sortOrder:
        sortedInfo.columnKey === "launch_angle" ? sortedInfo.order : null,
      ellipsis: true,
      width: 70,
    },
    {
      title: <p className="table-column-text">SPIN RATE</p>,
      dataIndex: "spin_rate",
      key: "spin_rate",
      sorter: (a: any, b: any) => a.spin_rate - b.spin_rate,
      sortOrder: sortedInfo.columnKey === "spin_rate" ? sortedInfo.order : null,
      ellipsis: true,
      width: 70,
    },
    {
      title: <p className="table-column-text">CARRY DISTANCE</p>,
      dataIndex: "carry_distance",
      key: "carry_distance",
      sorter: (a: any, b: any) => a.carry_distance - b.carry_distance,
      sortOrder:
        sortedInfo.columnKey === "carry_distance" ? sortedInfo.order : null,
      ellipsis: true,
      width: 70,
    },
  ];

  return (
    <div className="table-container">
      <Row justify="space-between" className="searchbar-and-button-container">
        <Col className="font-style-subheading">FS 4-October</Col>
        <Col>
          <ForeSight />
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={sampleData}
        loading={loading}
        onChange={handleChange}
        rowClassName={() => "editable-row"}
        pagination={{
          size: "small",
          showSizeChanger: true,
          onChange(page, pageSize) {
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
              setPageSize(pageSize);
            }, 1500);
          },
          pageSize,
          pageSizeOptions: ["10", "20", "30"],
          showTotal: (total, range) => `Rows per page: ${pageSize}`,
          style: {
            background: "#CDE5EF",
            color: "#047EB1",
            fontWeight: "bold",
            height: "45px",
            padding: "10px",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
            margin: "0px",
            fontSize: "15px",
          },
        }}
        style={{ fontSize: "15px", width: "100%" }}
      />
    </div>
  );
};

export default EventsTable;

import { Col, Layout, Row } from "antd";
import React from "react";
import { ReactComponent as GolfCloudLogo } from "@assets/icons/golfcloud-logo.svg";
import "./styles.css";

type Props = {
  children: any;
  pageTitle?: string;
};

const AuthLayout = (props: Props) => {
  return (
    <Layout
      style={{
        flex: 1,
        backgroundColor: "#00567A",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Row className="auth-layout-row"> */}
      {/* <Col span={12} className="auth-left"> */}

      <div>{!!props.pageTitle && <p>{props.pageTitle}</p>}</div>
      {/* </Col> */}
      {/* <Col span={12} className="auth-left"> */}
      {props.children}
      {/* </Col> */}
      {/* </Row> */}
    </Layout>
  );
};

export default AuthLayout;

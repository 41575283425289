import React from "react";
import { MainLayout } from "../../../layouts";
import { ReactComponent as UploadLogo } from "../../../../assets/icons/Upload-icon.svg";
import {
  Button,
  Upload,
  message,
  Col,
  Divider,
  Form,
  Row,
  Space,
  Typography,
  Modal,
} from "antd";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import type { UploadProps } from "antd";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { forwardRef, useEffect, useState } from "react";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import { toast } from "react-toastify";
import { Slide } from "react-toastify";
import GraphModal from "./graphs/modal/graphmodal";

const props: UploadProps = {
  name: "file",
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const SessionTime: React.FC = () => {
  const navigate = useNavigate();
  const [csv, setCsv] = useState<any>("");
  const routeChange = () => {
    let path = `/admin/session/upload`;
    navigate(path);
  };

  const { Text } = Typography;

  const getFile = (file: any, cb: Function) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    setCsv(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.warn("Error: ", error);
    };
  };

  const removeCsv = () => {
    toast.error(
      <>
        <p>csv removed</p>
      </>,
      {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
    setCsv("");
  };

  const props: UploadProps = {
    showUploadList: false,
    beforeUpload(file, FileList) {
      const isCsv = file.type === "text/csv";
      if (!isCsv) {
        message.error(`${file.name} is not a csv file`);
      }
      return isCsv || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status, type } = info.file;
      if (status !== "uploading" && type === "text/csv") {
        getFile(info.file.originFileObj, () => {});
      }
    },
    onRemove: () => {
      removeCsv();
    },
  };

  const formikMain = useFormik({
    initialValues: {
      name: "",
      partner_brand_id: undefined,
      csv_file: undefined,
    },
    onSubmit: async (values) => {
      setSubmitting(true);
      const giftCardsData = {
        name: values.name,
        partner_brand_id: values.partner_brand_id,
        csv_file: values.csv_file,
      };
      const csvParam = Boolean(csv);
      if (csvParam) {
        const addCsv = { csv_file: csv };
        await handleBulkUploadsFormSubmit({
          ...giftCardsData,
          ...addCsv,
        });
      } else {
        await handleBulkUploadsFormSubmit(giftCardsData);
      }

      setSubmitting(false);
      resetForm();
      navigate("/admin/gift-cards");
    },
    validationSchema: yup.object({
      name: yup.string().required("*Required Company"),
      //csv_file: yup.string().required('*Required '),
    }),
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    setFieldValue,
    resetForm,
    setSubmitting,
    isSubmitting,
  } = formikMain;

  const handleBulkUploadsFormSubmit = async (values: any) => {
    await values;
  };

  useEffect(() => {
    toast.info(
      <>
        <p>You can Bulk Upload your gift cards here!</p>
      </>,
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout headerName={"Sessions"}>
      <div style={{ height: "90vh", marginTop: "50px" }}>
        <FormikProvider value={formikMain}>
          <Row>
            <div className="session-box-shadow">
              <h1
                style={{
                  fontFamily: "Plus Jakarta Sans",
                  fontWeight: 700,
                  fontSize: 24,
                  textAlign: "center",
                }}
              >
                Upload Session Data
              </h1>
              <div className="upload-file-background">
                <Dragger
                  {...props}
                  maxCount={1}
                  style={{
                    border: "none",
                    background: "#f1fbff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: -45,
                    }}
                  >
                    <UploadLogo />
                    <h1
                      style={{
                        fontFamily: "Plus Jakarta Sans",
                        fontWeight: 700,
                        fontSize: 24,
                        padding: 12,
                        textAlign: "center",
                        color: "#000",
                        marginTop: -5,
                      }}
                    >
                      Drag & drop files or {""}
                      <span
                        style={{
                          color: "#096B96",
                          textDecorationLine: "underline",
                          cursor: "pointer",
                          fontSize: 24,
                          fontFamily: "Plus Jakarta Sans",
                          fontWeight: "bold",
                        }}
                      >
                        {"  "}
                        Browse
                      </span>
                    </h1>
                    <p
                      style={{
                        fontFamily: "Plus Jakarta Sans",
                        fontWeight: 400,
                        color: "#676767",
                        fontSize: 15,
                        textAlign: "center",
                        marginTop: -10,
                      }}
                    >
                      Supported formates: csv, xlsx from Trackman, Foresight &
                      FlightScope Only
                    </p>
                  </div>
                </Dragger>
              </div>
              {csv && (
                <Space
                  direction="horizontal"
                  style={{
                    justifyContent: "space-between",
                    width: "100%",
                    paddingTop: 10,
                  }}
                >
                  <Space>
                    <Text>{csv.name}</Text>
                  </Space>
                  <Button
                    size="small"
                    onClick={removeCsv}
                    danger
                    style={{ border: "none" }}
                  >
                    <DeleteOutlined />
                  </Button>
                </Space>
              )}
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  borderColor: "#047EB1",
                  color: "#FFF",
                  fontWeight: "bold",
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: 16,
                  padding: 12,
                  textAlign: "center",
                  cursor: "pointer",
                }}
                className="add-file-button"
                onClick={() => {
                  routeChange();
                }}
              >
                Upload files & Add session data to profile
              </div>
            </div>
          </Row>
        </FormikProvider>
      </div>
    </MainLayout>
  );
};
export default SessionTime;

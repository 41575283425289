import React from "react";
import "../../../style/sessions.css";
import "../../../style/index.css";
import { Button, Space, DatePicker, Select } from "antd";
import "./styles.css";
import Monitor from "../../../../assets/images/monitor1.png";
import Calender from "../../../../assets/images/calender1.png";
import Trackmann from "../../../../assets/images/Trackmann bg.png";
import Foresight from "../../../../assets/images/Foresight bg.png";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "../../../layouts";

const Sessions = () => {
  const navigate = useNavigate();
  const routeChange = () => {
    let path = `/admin/session/list`;
    navigate(path);
  };

  const onChange = (date: any, dateString: any) => {
    console.log(date, dateString);
  };

  const handleChange = (value: { value: string; label: React.ReactNode }) => {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  };

  return (
    <MainLayout
      headerName="Sessions"
      rightComponent={
        <div
          className="add-session-btn"
          onClick={() => {
            navigate("/admin/session");
          }}
        >
          + Add Session
        </div>
      }
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h3
              className="sub-header"
              style={{
                fontFamily: "Plus Jakarta Sans",
              }}
            >
              Recent
            </h3>
            <p
              style={{
                fontFamily: "Plus Jakarta Sans",
                paddingTop: 10,
                fontWeight: "500",
              }}
            >
              This week's sessions
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "-30px",
            }}
          >
            {/* <select
              style={{
                width: "200px",
                borderRadius: "50px",
                height: "35px",
                marginRight: "20px",
              }}
            >
              <option>Launch Monitor</option>
              <option>ForeSight</option>
              <option>Trackman</option>
            </select> */}
            <Select
              labelInValue
              defaultValue={{
                value: "Launch Moinitor",
                label: "Launch Monitor",
              }}
              style={{
                width: "200px",
                borderRadius: "50px",
                height: "35px",
                marginRight: "20px",
              }}
              onChange={handleChange}
              options={[
                {
                  value: "jack",
                  label: "Foresight",
                },
                {
                  value: "lucy",
                  label: "Trackmann",
                },
              ]}
            />
            <DatePicker
              onChange={onChange}
              style={{
                borderRadius: "50px",
                width: "200px",
                height: "35px",
              }}
            />
          </div>
        </div>
        <div
          className="row"
          style={{
            cursor: "pointer",
          }}
        >
          {[1, 2, 3, 4, 5].map((item, index) => {
            return (
              <div
                className="card-container card-1"
                onClick={() => {
                  routeChange();
                }}
              >
                <img
                  src={Foresight}
                  alt="name"
                  style={{
                    backgroundColor: "#FFF",
                    width: "95px",
                    height: "24px",
                    marginBottom: 14,
                    marginLeft: 94.7,
                    borderBottomLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                />
                <h3 className="card-style">FS 4-October</h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Calender}
                    alt="name"
                    style={{
                      width: "10px",
                      height: "10px",
                    }}
                  />

                  <p
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: 10,
                      fontWeight: 500,
                      padding: 6,
                    }}
                  >
                    Mon. Oct 4,2021 - 04:00 PM EST
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Monitor}
                    alt="name"
                    style={{
                      width: "10px",
                      height: "10px",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: 10,
                      fontWeight: 500,
                      padding: 6,
                    }}
                  >
                    Foresight Session
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="row"
          style={{
            cursor: "pointer",
          }}
        >
          {[1, 2, 3, 4, 5].map((item, index) => {
            return (
              <div className="card-container card-2">
                <img
                  src={Trackmann}
                  alt="name"
                  style={{
                    width: "95px",
                    height: "24px",
                    marginBottom: 14,
                    marginLeft: 95,
                  }}
                />
                <h3 className="card-style">TM 4-October</h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Calender}
                    alt="name"
                    style={{
                      width: "10px",
                      height: "10px",
                    }}
                  />

                  <p
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: 10,
                      fontWeight: 500,
                      padding: 6,
                    }}
                  >
                    Mon. Oct 4,2021 - 04:00 PM EST
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Monitor}
                    alt="name"
                    style={{
                      width: "10px",
                      height: "10px",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: 10,
                      fontWeight: 500,
                      padding: 6,
                    }}
                  >
                    Trackman Session
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </MainLayout>
  );
};

export default Sessions;

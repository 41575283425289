import { useState } from "react";
import { MainLayout } from "../../../../layouts";
import EventsTable from "../../tables/EventsTable";
import { useNavigate } from "react-router-dom";
import Track from "../../../../../assets/images/trackman.png";
import { Modal } from "antd";
import AreaApex from "../../../../charts/areaApex";

const SessionTable = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isModalVisible, setIsModalVisisble] = useState(false);

  const showModal = () => {
    setIsModalVisisble(true);
  };

  const handleOk = () => {
    setIsModalVisisble(false);
  };

  const handleCancel = () => {
    setIsModalVisisble(false);
  };

  return (
    <MainLayout
      headerName={"Stats for TM 4-October"}
      subTitle1={"Sessions / FS4-October"}
      subTitle2={"Mon. Oct 4,2021 - 04:00 PM EST"}
      rightComponent={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={Track} alt="logo" />
          <p
            onClick={() => showModal()}
            style={{
              fontFamily: "Plus Jakarta Sans",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "14px",
              textDecorationLine: "underline",
              cursor: "pointer",
              color: "#047EB1",
            }}
          >
            Graph View
          </p>
        </div>
      }
    >
      <EventsTable />
      <Modal
        centered
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={false}
        maskClosable={true}
      >
        <AreaApex />
      </Modal>
    </MainLayout>
  );
};

export default SessionTable;

import { Col, Row, Form, Typography } from "antd";
import { useFormik, FormikProvider } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import {
  FormikDatePicker,
  FormikDatePickers,
  FormikTextField,
} from "../inputFeilds/InputFeilds";

const SelectDateTime = () => {
  const navigate = useNavigate();
  const [posterBase, setPosterBase] = useState<any>("");

  // Function for reading image form system

  // Writing formik logic right here as it is very specific
  const formikMain: any = useFormik({
    initialValues: {
      name: "",
      eventDates: "",
      scores_upload_cutoff_time: "",
      registration_deadline: "",
      event_type: undefined,
      strokeplay_course: undefined,
      team_members: undefined,
      scoring: undefined,
      event_poster: "",
      course_address: "",
      rules: "",
      par_value: "",
      flights: "",
      smash_points: "",
      world_points: "",
      rewards: [
        {
          flight_id: undefined,
          position: 1,
          smash_points: "",
          world_points: "",
        },
      ],
      event_entry: "",
      event_fees: "",
      multiple_entries: "",
      has_promocodes: "",
      promocodes: [],
    },
    onSubmit: async (values) => {
      setSubmitting(true);
      const eventCreationData = {
        name: values.name,
        start_time: values.eventDates[0],
        end_time: values.eventDates[1],
        registration_deadline: values.registration_deadline,
      };
      const posterParam = Boolean(posterBase);
      if (posterParam) {
        const addPoster = { event_poster: posterBase };
        await handleCreateEventFormSubmit({
          ...eventCreationData,
          ...addPoster,
        });
      } else {
        await handleCreateEventFormSubmit(eventCreationData);
      }
      setSubmitting(false);
    },
    validationSchema: yup.object({
      name: yup.string().required("*Required Event name"),
      eventDates: yup.array().required("*Required at least event start Date"),
      registration_deadline: yup
        .string()
        .required("*Required Session date and time"),
    }),
  });

  const { errors, getFieldProps, touched, setFieldValue, setSubmitting } =
    formikMain;

  const handleCreateEventFormSubmit = async (values: any) => {
    await values;
  };

  return (
    <>
      <FormikProvider value={formikMain}>
        <Col
          style={{
            width: "90%",
          }}
        >
          <Col>
            <FormikDatePicker
              format="dddd, MM/DD/YYYY h:mm a"
              showTime
              // disabledDate={
              //   values.event_type === "Strokeplay"
              //     ? disableStrokeplayRegDate
              //     : disableRegDate
              // }
              label="SESSION DATE & TIME"
              style={{ color: "#9FA2B4" }}
              autoComplete="off"
              fieldValue="registration_deadline"
              setFieldValue={setFieldValue}
              error={
                Boolean(
                  errors.registration_deadline && touched.registration_deadline
                ) && errors.registration_deadline
              }
              {...getFieldProps("registration_deadline")}
            />
          </Col>
          <Row>
            <Col
              style={{
                width: "100%",
              }}
            >
              <FormikTextField
                label="SESSION NAME"
                style={{ color: "#9FA2B4", marginTop: -10 }}
                autoComplete="off"
                error={Boolean(errors.name && touched.name) && errors.name}
                placeholder="Enter Session Name"
                {...getFieldProps("name")}
              />
            </Col>
            <Col span={8}></Col>
            <Col span={8}></Col>
          </Row>
        </Col>
      </FormikProvider>
    </>
  );
};

export default SelectDateTime;

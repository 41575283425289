export const sampleData: any = [
  {
    name: "Driver",
    key: "1",
    club_speed: "118.2176092",
    ball_speed: "173.8499911",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "2",
    club_speed: "118.2176092",
    ball_speed: "173.8499911",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "3",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "4",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "14.262",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "5",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "6",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "7",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "8",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "9",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "10",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },

  {
    name: "Driver",
    key: "11",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "12",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },

  {
    name: "Driver",
    key: "13",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "14",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "15",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "16",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },

  {
    name: "Driver",
    key: "17",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "18",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },

  {
    name: "Driver",
    key: "19",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "20",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",

    carry_distance: "24.0676526",
  },
  {
    name: "Driver",
    key: "21",
    club_speed: "118.2176092",
    ball_speed: "189.5501521",
    launch_angle: "10.049",
    spin_rate: "1600",
    carry_distance: "24.0676526",
  },
];

import { forwardRef } from "react";
import { DatePicker, Form, Input, Radio, Select } from "antd";
import { ReactComponent as DropDownOutlined } from "../../../../../assets/icons/dropdown-icon.svg";
import moment from "moment";

const { Option } = Select;

const { Item } = Form;
const { RangePicker } = DatePicker;

export const FormikTextField = forwardRef(
  (
    {
      error,
      label,
      name,
      onChange,
      onBlur,
      placeholder,
      value,
      style,
      type,
      ...props
    }: any,
    ref
  ) => (
    <Item
      style={style}
      {...(Boolean(error) && { validateStatus: "error", help: error })}
    >
      {!!label && <p className="text-field-label">{label}</p>}

      {type === "textarea" ? (
        <Input.TextArea
          className="form-textarea-field-custom"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          value={value}
          rows={6}
          {...props}
        />
      ) : (
        <Input
          className="form-input-field-custom"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          value={value}
          {...props}
        />
      )}
    </Item>
  )
);

export const FormikDatePickers = forwardRef(
  (
    {
      error,
      label,
      name,
      onChange,
      onBlur,
      placeholder,
      value,
      style,
      setFieldValue,
      disabledDate,
      fieldValue,
      format,
      showTime,
      ...props
    }: any,
    ref
  ) => (
    <Item
      style={style}
      {...(Boolean(error) && { validateStatus: "error", help: error })}
    >
      {!!label && <p className="text-field-label">{label}</p>}

      <DatePicker
        format={format}
        showTime={showTime}
        allowClear={false}
        suffixIcon={<DropDownOutlined />}
        className="form-date-field-custom"
        placeholder={placeholder}
        disabledDate={disabledDate}
        name={name}
        onChange={(date: any, dateString: any) => {
          setFieldValue(fieldValue, date);
        }}
        value={value !== "" ? moment(value) : undefined}
        onBlur={onBlur}
        ref={ref}
        {...props}
      />
    </Item>
  )
);

export const FormikRadioInput = forwardRef(
  (
    {
      error,
      label,
      name,
      onChange,
      onBlur,
      placeholder,
      value,
      option1,
      option2,
      style,
      prefix,
      type,
      disabled,
      ...props
    }: any,
    ref
  ) => (
    <Item
      style={style}
      {...(Boolean(error) && { validateStatus: "error", help: error })}
    >
      {!!label && <p className="text-field-label">{label}</p>}

      <Radio.Group
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        <Radio value={option1}>{option1}</Radio>
        {option2 && <Radio value={option2}>{option2}</Radio>}
      </Radio.Group>
    </Item>
  )
);

export const FormikDatePicker = forwardRef(
  (
    {
      error,
      label,
      name,
      onChange,
      onBlur,
      placeholder,
      value,
      style,
      setFieldValue,
      disabledDate,
      fieldValue,
      format,
      showTime,
      ...props
    }: any,
    ref
  ) => (
    <Item
      style={style}
      {...(Boolean(error) && { validateStatus: "error", help: error })}
    >
      {!!label && <p className="text-field-label">{label}</p>}

      <DatePicker
        format={format}
        showTime={showTime}
        allowClear={false}
        suffixIcon={<DropDownOutlined />}
        className="form-date-field-custom"
        placeholder={placeholder}
        disabledDate={disabledDate}
        name={name}
        onChange={(date: any, dateString: any) => {
          setFieldValue(fieldValue, date);
        }}
        value={value !== "" ? moment(value) : undefined}
        onBlur={onBlur}
        ref={ref}
        {...props}
      />
    </Item>
  )
);

export const FormikRangePicker = forwardRef(
  (
    {
      error,
      label,
      name,
      onChange,
      onBlur,
      placeholder,
      value,
      value2,
      style,
      setFieldValue,
      fieldValue,
      format,

      ...props
    }: any,
    ref
  ) => (
    <Item
      style={style}
      {...(Boolean(error) && { validateStatus: "error", help: error })}
    >
      {!!label && <p className="text-field-label">{label}</p>}

      <RangePicker
        format={format}
        showTime
        allowClear={false}
        suffixIcon={<DropDownOutlined />}
        className="form-date-field-custom"
        placeholder={placeholder}
        name={name}
        onChange={(date, dateString) => {
          setFieldValue(fieldValue, date);
        }}
        value={value !== "" ? [moment(value[0]), moment(value[1])] : ""}
        onBlur={onBlur}
        ref={ref}
        {...props}
      />
    </Item>
  )
);

export const FormikSelect = forwardRef(
  (
    {
      error,
      label,
      name,
      onChange,
      onBlur,
      placeholder,
      value,
      options,
      style,
      setFieldValue,
      fieldValue,
      ...props
    }: any,
    ref
  ) => (
    <Item
      style={style}
      {...(Boolean(error) && { validateStatus: "error", help: error })}
    >
      {!!label && <p className="text-field-label">{label}</p>}

      <Select
        className="form-select-field-custom"
        suffixIcon={<DropDownOutlined />}
        placeholder={placeholder}
        ref={ref}
        value={value}
        onChange={(value: any) => {
          setFieldValue(fieldValue, value);
        }}
        {...props}
      >
        {options.map((option: any) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </Item>
  )
);

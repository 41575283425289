import AreaApex from "../../../../charts/areaApex";
import { MainLayout } from "../../../../layouts";

const SessionsGraph = () => {
  return (
    <MainLayout headerName="Session Graphs">
      <div style={{ height: "85vh" }}>
        <AreaApex height={500} />
      </div>
    </MainLayout>
  );
};

export default SessionsGraph;

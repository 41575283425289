import { MainLayout } from "../../../../layouts";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import TrackMan from "../../../../../assets/images/trackman-logo.svg";
import React, { useState } from "react";
import AreaApex from "../../../../charts/areaApex";

const Circle = ({
  title,
  imgSrc,
  subTitle,
  showSub = true,
}: {
  title: string;
  imgSrc: any;
  subTitle: string;
  showSub: boolean;
}) => {
  return (
    <div>
      <div className="circle">
        {showSub && (
          <img
            style={{ width: "50px", height: "50px", borderRadius: "25px" }}
            src={imgSrc}
          />
        )}
        {!showSub && <div className="all">All</div>}
      </div>
      {showSub && <div className="small-circle">{subTitle}</div>}
      <p
        className="cirle-title"
        style={{ marginTop: showSub ? "5px" : "20px" }}
      >
        {title}
      </p>
    </div>
  );
};

const SessionList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isModalVisible, setIsModalVisisble] = useState(false);

  const showModal = () => {
    setIsModalVisisble(true);
  };

  const handleOk = () => {
    setIsModalVisisble(false);
  };

  const handleCancel = () => {
    setIsModalVisisble(false);
  };
  const dataSource = [
    {
      title: "All Equipments",
      imgSrc: "",
      subTitle: "",
      showSub: false,
    },
    {
      title: "XXIO Premium",
      imgSrc: require("../../../../../assets/sessionIcons/circleIcons/P.png"),
      subTitle: "P",
      showSub: true,
    },
    {
      title: "Ping G400 9°",
      imgSrc: require("../../../../../assets/sessionIcons/circleIcons/D.png"),
      subTitle: "D",
      showSub: true,
    },
    {
      title: "Ping G400 15°",
      imgSrc: require("../../../../../assets/sessionIcons/circleIcons/3w.png"),
      subTitle: "3w",
      showSub: true,
    },
    {
      title: "Titleist AP2 22°",
      imgSrc: require("../../../../../assets/sessionIcons/circleIcons/3i.png"),
      subTitle: "3i",
      showSub: true,
    },
    {
      title: "Titleist AP2 25°",
      imgSrc: require("../../../../../assets/sessionIcons/circleIcons/4i.png"),
      subTitle: "4i",
      showSub: true,
    },
    {
      title: "Titleist AP2 28°",
      imgSrc: require("../../../../../assets/sessionIcons/circleIcons/5i.png"),
      subTitle: "5i",
      showSub: true,
    },
    {
      title: "Titleist AP2 30°",
      imgSrc: require("../../../../../assets/sessionIcons/circleIcons/6i.png"),
      subTitle: "6i",
      showSub: true,
    },
  ];

  const gridDataSource = [
    {
      title: "71",
      subTitle: "Swing Speed",
      miniTitle: "MPH",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/ss.png"),
    },
    {
      title: "32",
      subTitle: "Energy Transfer",
      miniTitle: "",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/et.png"),
    },
    {
      title: "R",
      subTitle: "Velocity Direction",
      miniTitle: "",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/vd.png"),
    },
    {
      title: "71",
      subTitle: "Ball Speed",
      miniTitle: "MPH",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/bs.png"),
    },
    {
      title: "32",
      subTitle: "Club Speed",
      miniTitle: "MPH",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/et.png"),
    },
    {
      title: "108 '",
      subTitle: "Launch Angle",
      miniTitle: "",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/vd.png"),
    },
    {
      title: "4512",
      subTitle: "Spin Rate",
      miniTitle: "RPM",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/bs.png"),
    },
    {
      title: "3210",
      subTitle: "Back Spin",
      miniTitle: "RMP",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/cs.png"),
    },
    {
      title: "32",
      subTitle: "Club Speed",
      miniTitle: "MPH",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/la.png"),
    },
    {
      title: "108 '",
      subTitle: "Launch Angle",
      miniTitle: "",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/sr.png"),
    },
    {
      title: "4512",
      subTitle: "Spin Rate",
      miniTitle: "RPM",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/ta.png"),
    },
    {
      title: "3210",
      subTitle: "Back Spin",
      miniTitle: "RMP",
      imgSrc: require("../../../../../assets/sessionIcons/SquareIcons/tw.png"),
    },
  ];

  return (
    <MainLayout
      headerName={"Stats for TM 4-October"}
      subTitle1={"Sessions / FS4-October"}
      subTitle2={"Mon. Oct 4,2021 - 04:00 PM EST"}
      rightComponent={
        <div>
          <img src={TrackMan} alt="logo" />
          <p
            onClick={() => navigate("/admin/session/table")}
            style={{
              fontFamily: "Plus Jakarta Sans",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "14px",
              textDecorationLine: "underline",
              cursor: "pointer",
              color: "#047EB1",
            }}
          >
            Table View
          </p>
        </div>
      }
    >
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        {dataSource.map((item, index) => {
          return (
            <Circle
              key={index}
              title={item.title}
              imgSrc={item.imgSrc}
              subTitle={item.subTitle}
              showSub={item.showSub}
            />
          );
        })}
      </div>
      <div className="grid-container">
        {gridDataSource.map((item, index) => {
          return (
            <div
              key={index}
              className="session-boxes-shadow"
              onClick={() => showModal()}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <img
                  src={item.imgSrc}
                  style={{ width: "40px", height: "30px" }}
                />
              </div>
              <div>
                <h1>
                  {item.title} <span>{item.miniTitle}</span>
                </h1>
                <p className="card-subtitle">{item.subTitle}</p>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        centered
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={false}
        maskClosable={true}
      >
        <AreaApex />
      </Modal>
    </MainLayout>
  );
};

export default SessionList;

import AreaApex from "../../charts/areaApex";
import PieCharts from "../../charts/pieChart";
import PieCharts2 from "../../charts/pieCharts2";
import "./styles.css";
import PieCharts3 from "../../charts/pieCharts3";
import { MainLayout } from "../../layouts";

function Graphs() {
  return (
    <MainLayout>
      <div>
        <div
          style={{
            display: "flex",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              border: "solid",
              borderWidth: "0.5px",
              borderColor: "#004494",
              borderRadius: 10,
              padding: 20,
              width: "55%",
              height: 350,
              backgroundColor: "#ffffff",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: 24,
                textAlign: "left",
                padding: 6,
              }}
            >
              Graph
            </p>
            {/* <AreaGraphs /> */}
            <AreaApex />
            <p
              style={{
                textAlign: "center",
              }}
            >
              Cash
            </p>
          </div>
          <div
            style={{
              border: "solid",
              borderWidth: "0.5px",
              borderColor: "#004494",
              borderRadius: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "30%",
              padding: 20,
              height: 350,
              marginLeft: 20,
              backgroundColor: "#ffffff",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: 24,
                textAlign: "left",
                padding: 6,
              }}
            >
              Total Charts
            </p>
            <PieCharts />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              border: "solid",
              borderWidth: "0.5px",
              borderColor: "#004494",
              borderRadius: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "30%",
              padding: 20,
              height: 350,
              marginTop: 20,
              backgroundColor: "#ffffff",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: 24,
                textAlign: "left",
                padding: 6,
              }}
            >
              Total Charts
            </p>
            <PieCharts2 />
          </div>
          <div
            style={{
              border: "solid",
              borderWidth: "0.5px",
              borderColor: "#004494",
              borderRadius: 10,
              justifyContent: "center",
              alignItems: "center",
              width: "30%",
              padding: 20,
              height: 350,
              marginTop: 20,
              marginLeft: 20,
              backgroundColor: "#ffffff",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: 24,
                textAlign: "left",
                padding: 6,
              }}
            >
              Total Charts
            </p>
            <PieCharts3 />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Graphs;

import React from "react";
import ReactApexChart from "react-apexcharts";

const AreaApex = ({ height = 350 }) => {
  const series = [
    {
      name: "cash",
      data: [61, 40, 28, 51, 42, 109, 100],
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={{
          chart: {
            toolbar: {
              show: false,
            },
            height,
            type: "area",
            fontFamily: "Plus Jakarta Sans",
          },
          fill: {
            colors: ["#53CF8E", "#D9D9D9"],
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
            width: 2,
            colors: ["#53CF8E"],
          },
          markers: {
            size: [4, 7],
            colors: ["#53CF8E"],
            strokeColors: "#fff",
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
              size: undefined,
              sizeOffset: 3,
            },
          },
          xaxis: {
            categories: ["0", "1", "2", "3", "4", "5", "6"],
            labels: { style: { fontSize: "10px", colors: "#000" } },
          },

          yaxis: {
            labels: {
              formatter: (val: number) => {
                return val + "";
              },
            },
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
            marker: {
              fillColors: ["#53CF8E"],
            },
          },
        }}
        series={series}
        type="area"
        width="100%"
        height="200"
      />
    </div>
  );
};

export default AreaApex;
